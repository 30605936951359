<template>
	<div class="navbar-right navbar-shrink-desktop">
		<ul class="navbar-nav d-flex navbar-first-ul">
			<!-- <button v-if="isTablet" class="btn btn-secondary tablet-business my-auto px-2" @click="goTo('https://business.pactcoffee.com/')">pact for business</button> -->
			<li class="basket-dropdown">
				<button class="basket-toggler px-2" type="button" @click="basketclickAction()" >
					<span>
						<span class="icon" />
						<span
							v-if="totalItems > 0"
							:class="{
								'ml-2 navbar-items-number': true,
								'char-1': totalItems < 10,
								'chars-2': (totalItems >= 10) & (totalItems < 100),
								'chars-3': totalItems >= 100,
							}"
							>{{ totalItems }}</span
						>
					</span>
				</button>
			</li>
			<li v-if="isTablet || !isMobile" class="account-dropdown">
				<button
					type="button"
					class="account-toggler inverted-hover px-2"
					@click="handleClick"
				>
					<span id="account-icon" class="icon" />
				</button>
			</li>
		</ul>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { mixinIsMobile } from '../utils/mixins/isMobile'

export default {
	name: 'NavbarUserButtons',
	mixins: [mixinIsMobile],
	computed: {
		...mapGetters('user', ['isOnline', 'getShowSidebar']),
		...mapGetters('basket', ['totalItems']),
		isInAccount() {
			return window.location.pathname.startsWith('/account')
		},
	},
	methods: {
		...mapActions('user', ['openSidebar', 'closeSidebar']),
		...mapActions('basket', ['basketValidations']),
    basketclickAction() {
      if(this.totalItems > 0)
        this.basketValidations()
      setTimeout(() => {
        toggleBasket()
      }, 500);
    },
		handleClick() {
			var href = this.isOnline ? '/account' : '/login'
			if (this.isMobile) {
				if (this.isInAccount) {
					if (this.getShowSidebar) this.closeSidebar()
					else this.openSidebar()
				} else {
					var hrefMobile = this.isOnline ? '/account/details' : '/login'
					this.routeRedirect(hrefMobile)
					this.openSidebar()
				}
			} else {
				this.routeRedirect(href)
			}
		},
		routeRedirect(href) {
			return (window.location.href = href)
		},
		goTo(url) {
			window.location = url
		},
	},
}
</script>
